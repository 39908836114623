.large-hero {
	position: relative;

    figcaption {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        text-align: center;
    }

	&__text-content {
		position: absolute;
		top: 85%;
		transform: translateY(-85%);
	}


	&__title {
		font-weight: 500;
		margin: 0;
		font-size: 1.1rem;

		@mixin atSmall {
			font-size: 2rem;
		}

		@mixin atMedium {
			font-size: 3.2rem;
		}

		@mixin atLarge {
			font-size: 4.8rem;
		}
	}

	&__subtitle {
		font-weight: 300;
		color: #2f5572;
		font-size: 1.1rem;
		margin: 0;

		@mixin atSmall {
			font-size: 2rem;
		}
	}

	&__description {
		color: #FFF;
		font-size: 1rem;
		font-weight: 100;
		text-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
		max-width: 24rem;
		margin-left: auto;
		margin-right: auto;

		@mixin atSmall {
			font-size: 1.2rem;
		}
	}

}






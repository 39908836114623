html {
    font-family: 'CalibreWeb-Light', sans-serif;
    letter-spacing: .4px;
    font-style:normal;
    font-size: 17px;
}

body { 
	background-color: #2A2A2A;
	color: #eceff1
}

img {
    max-width: 100%;
    height: auto;
}

p {color: #eceff1} /*#aebfc7*/ /*#90a4ae*/

/* CSS for ArtSigns.com */
@import "base/_global";
@import "base/_variables";
@import "base/_mixins";
@import "modules/_headings";
@import "modules/_large-hero";
@import "modules/_carousel";


$mainOrange: #ff9800;


/* course */

h1, h2, h4 {font-family: 'Playfair Display', sans-serif;
    font-weight:100;
    letter-spacing: .4px;
}

h3 {font-family: 'CalibreWeb-Regular', sans-serif;



    }

p {
    font-family: inherit;
    font-weight:100;
    font-size: 17px;
}

.page-footer a {
    color: #fafafa;
}
.page-footer a:hover {
    text-decoration: underline;
}

#home nav .home a,
#about nav .about a,
#new nav .new a,
#location nav .location a,
#quote nav .quote a {
    color: #263238;
    cursor: default;
}

nav img {
    margin-top:8px;
    height:48px;
    display: block;
}

nav ul a {
    font-family: 'CalibreWeb-Regular', sans-serif;

}

.card-title {font-family: 'CalibreWeb-Regular', sans-serif;
    font-weight:700 !important;}

.icon-block {
    padding: 0 15px;
}

.icon-block .material-icons {
    font-size: inherit;
}

.officesigns .card-action
{
    position: relative;
    background-color: inherit;
    border-top: 0;
    padding: 0;
    min-height: 55px;
}


/*** Small Screen up to 600px ***/


@media screen and (max-width: 600px) {

    .officesigns .card-action {
        min-height: 55px;
    }
    .bubble {
        min-height: 200px;
    }

    .bubble span.bl {
        font-size: 12px;
    }

    .breadcrumb {
        font-size: 12px;
    }
/*
    h1 {
        font-size: 2.28rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.5rem;
    }*/

}

@media screen and (min-width: 440px) {
    .bubble {
        min-height: 225px;
    }
}

@media screen and (min-width: 540px) {
    .bubble {
        min-height: 250px;
    }
}

/*** Medium Screen between 600px and 992px  ***/
@media screen and (min-width: 600px) and (max-width: 992px) {
    .officesigns .card-action {
        min-height: 65px;
    }

    .officesigns a {
        font-size: 16px;
    }

    .officesigns p {
        font-size: 16px;
    }

/*    h1 {
        font-size: 2.28rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 2rem;
    }


    h5 {
        font-size: 1.5rem;
    }
*/

}

/*** Large Screen between 993px and 1200px ***/
@media screen and (min-width: 993px) and (max-width: 1200px) {

    .officesigns .card-action {
        min-height: 65px;
    }

    .officesigns a {
        font-size: 16px;
    }

    .officesigns p {
        font-size: 16px;
    }

    a {
        font-size: 16px;
    }
/*    h1 {
        font-size: 2.28rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.5rem;
    }*/

}

/*** Large Screen over 1200px ***/
@media screen and (min-width: 1200px) {

    .officesigns .card-action {
        min-height: 70px;
    }

    .officesigns a {
        font-size: 17px;
    }

    .officesigns p {
        font-size: 17px;
    }
/*
    h1 {
        font-size: 2.28rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.5rem;
    }*/
}

@media only screen and (min-width: 993px) {
    .container {
        width: 85%;
    }
}*/

#c1 {
    background-image: url("../images/c2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    background-position: center;

}

#c3 {
    background-image: url("../images/c3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    background-position: center;

}

.card.small {
    min-height: 465px;
}


.artsigns-grey {
    background-color: #2A2A2A !important;
}

.caption-underlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(0.3, transparent), to(rgba(0, 0, 0, 0.74902)));
}

.grid-element-title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    margin: 0;
    line-height: 2.8rem;
    padding: 0 10px;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gallery a {
    color: #c5c5c5;
}
.gallery a:hover {
    color: #fff;
}

.card.card-title {
    font-size: 16px;
}



.officesigns .card-action p {
    padding: 0;
    margin: 0;
    color: #90a4ae;
}

.card {
    background-color: #1d1d1d;
}


.aboutus p {
    color: #90a4ae;
}

.heart {
    fill: red;
    position: relative;
    top: 5px;
    width: 50px;
    animation: pulse 1s ease infinite,
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
}

.icon {
    color: #ff9800 !important;
}
.process-caption {
    font-size: 1.7rem;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}
.process p {

    text-align: center;}
.process .list ul li {  list-style: square outside none !important;}

section.processPanel {
    margin-top: 20px !important;
}

section {
    margin-top: 75px;
    margin-bottom: 75px;
}

section.entry {
    margin-top: 50px;
    margin-bottom: 50px;
}

.block {
    margin-top: 50x;
    margin-bottom: 50px;
}

section.aboutUs p {
    font-size:24px;
}
.officesigns a {
    text-decoration: none;
    color: #90a4ae;
}

.officesigns a:hover {
    color: #90a4ae;
    text-decoration: underline;
}

.read_more {
    text-align: center;
    font-size: 1.625rem;
    font-weight: 300;
    margin-top: 2.8315rem;
    margin-bottom: 0.809rem;
}

.read_more a {
    color: #ee6e73;
}

.read_more a:hover {
    color: #c46464;
}

.officesigns a[class^='underline'] {
    text-decoration: none;
    padding-left: 0.125em;
    padding-right: 0.125em;
    padding-bottom: 0.1em;
    position: relative;
}
.officesigns a[class^='underline']::after {
    content: '';
    position: absolute;
    top: 100%;
    border-bottom: 0.125rem solid currentColor;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.officesigns a[class^='underline']:hover::after {
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.officesigns a.underline_from_left::after {
    left: 0;
    right: 100%;
}
.officesigns a.underline_from_left:hover::after {
    right: 0;
}
.officesigns a.underline_from_right::after {
    right: 0;
    left: 100%;
}
.officesigns a.underline_from_right:hover::after {
    left: 0;
}

.officesigns h3 {
    line-height: 110%;
    margin: 0.5rem 0 0.4rem 0;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    font-size: 16px;
}

#map {
    width: auto;
    height: 432px;
}

h2 {color: white;}

.wrap-fluid {
    box-sizing: border-box;
    min-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
}

.signStory-quote {
    color: white;
    font-size: 24px;
}

blockquote {margin:20px 0;padding-left:1.5rem;border-left:5px solid #ff9800}
figure {margin: 0px;}

.figcon {
    position: relative;
    height:auto;
}
figure {
    display: block;
}

figure img{
    display: block;
}
.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  background: linear-gradient(to bottom, rgba(38,50,56,0), rgba(38,50,56,1));
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
}

.map-container {
    width: 100%;
    min-height: 480px;
}
.map-container iframe{
    width: 100%;
    min-height: 480px;
    display: block;
    pointer-events: none;
    position: relative; /* IE needs a position other than static */
}
.map-container iframe.clicked{
    pointer-events: auto;
}

.signtype a {
    margin-left: 5px;
}

#client-logos img {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: inline-block;
}

.cat-name {
    font-weight: 600;
}

.cat-title {
    font-family: 'CalibreWeb-Light', sans-serif;
    
}

/*** Freeform ***/

label.required:after {
    display: inline-block !important;
    content: "*" !important;
    color: #d00 !important;
    margin-left: 5px !important;
    position: relative !important;
    top: 0 !important;
    opacity: 1 !important;
}

.errors {
    color: red;
}

ul.errors > li {
    color: red;
}

.submit-align-left {
    text-align: left;
}

.submit-align-right {
    text-align: right;
}

.submit-align-center {
    text-align: center;
}

.submit-align-left button:not(:first-of-type),
.submit-align-right button:not(:first-of-type),
.submit-align-center button:not(:first-of-type) {
    margin-left: 5px;
}

.submit-align-spread button:first-child {
    float: left;
}

.submit-align-spread button:last-child {
    float: right;
}

.side-nav li>a {
    padding: 0 16px;
}

a.anchor {
    font-size: 16px !important;
}

.entry-image, .entry-quote, .entry-text, .entry-video  {
/*    box-sizing: border-box;
    min-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    margin-bottom: 20px;*/
}

/*** Buttons ***/

.custom-green {
    background-color: #00CF75!important;
}

.custom-blue {
    margin: 30px 0 30px 0;
    background-color: #2F4858!important;
}

.text { line-height: 2; }

.text a {
    color:#FF9F54;
    text-decoration: underline;
    display: inline-block;
    line-height: 1;
}
.text a:hover {
    color: #FF9F54;
    display: inline-block;
    line-height: 1;
}

.text li::before {content: "•"; color: #FF9F54;
  display: inline-block; width: 1em;
  margin-left: 2em}

.tabs .indicator {
    background-color: #ff9800;
}

.card-content a {
    color:#FF9F54;
}
.card-content a:hover {
    color: #FF9F54;
    font-weight: 500;
    display: inline-block;
    line-height: 1;
}

.card-content text li::before {content: "•"; color: #FF9F54;
  display: inline-block; width: 1em;
  margin-left: 2em}

.card-content .divider {
    width: 50%;
}

.footerlinks a {
    font-weight: 300;
    font-size: 15px;
    display: block;
    line-height: 40px;
}

.terms a {
    font-weight: 300;
    display: inline-block;
    font-size: .8em;
    margin-right: 1em;
}

.terms a:first-child {
    margin-left: 1em;
}

.sml {
    font-size: .6em;
}

.btn.waves-effect.waves-light.navq { border: 2px solid #fff; }

.bml { margin-bottom: 100px; }
.bmm { margin-bottom: 50px; }
.bms { margin-bottom: 25px; }
.bmxs { margin-bottom: 10px; }

.tpl { padding-top: 100px; }
.tpm { padding-top: 50px; }
.tps { padding-top: 25px; }
.tpxs { padding-top: 10px; }

.pr-6 { padding-right: 1.5em; }

.underline {     padding-bottom: 5px;
    border-bottom: 1px solid #90a4ae;}

ul.clientList {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
}

/*.navbar-fixed {
height: 103px;
}

@media only screen and (min-width: 601px) {
.navbar-fixed {
    height: 111px;
}
}*/


/* -- Fontello */

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    font-size: 120%;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


span.headline-link {
  font-size: 1em;
  margin: 0;
  float: left;
  margin-left: -45px;
  line-height: 1em;
  padding-right: 2px;
}

span.headline-link a {
  -webkit-box-shadow: none;
  background-image: none;
  color: #FF9800;
}

span.headline-link a {
  background-color: transparent;
}

h2.white-text > span.headline-link > a > i {
  visibility: hidden;
}

h2.white-text:hover > span.headline-link > a > i {
  visibility: visible;
}

span.headline-link:hover span.headline-link > a > i {
  visibility: visible;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}



@font-face {
    font-family: 'CalibreWeb-Light';
    src:url('/fonts/CalibreWeb-Light.eot') format('eot'),
        url('/fonts/CalibreWeb-Light.woff2') format('woff2'),
        url('/fonts/CalibreWeb-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CalibreWeb-Regular';
    src:url('/fonts/CalibreWeb-Regular.eot') format('eot'),
        url('/fonts/CalibreWeb-Regular.woff2') format('woff2'),
        url('/fonts/CalibreWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgEM86xRbPQ.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTPYgEM86xRbPQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTLYgEM86xRbPQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgEM86xQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v25/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* Fontello */

@font-face {
    font-family: 'fontello';
    src: url('/fonts/fontello.eot?44411434');
    src: url('/fonts/fontello.eot?44411434#iefix') format('embedded-opentype'),
    url('/fonts/fontello.woff2?44411434') format('woff2'),
    url('/fonts/fontello.woff?44411434') format('woff'),
    url('/fonts/fontello.ttf?44411434') format('truetype'),
    url('/fonts/fontello.svg?44411434#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
font-style: normal;
font-weight: normal;
speak: none;

display: inline-block;
text-decoration: inherit;
width: 1em;
margin-right: .2em;
text-align: center;
/* opacity: .8; */

/* For safety - reset parent styles, that can break glyph codes*/
font-variant: normal;
text-transform: none;

/* fix buttons height, for twitter bootstrap */
line-height: 1em;

/* Animation center compensation - margins should be symmetric */
/* remove if not needed */
margin-left: .2em;

/* you can be more comfortable with increased icons size */
font-size: 120%;

/* Font smoothing. That was taken from TWBS */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

/* Uncomment for 3D effect */
/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}


h1 {
	font-size: 1.8rem;
	@mixin atSmall { font-size: 2.2rem; }
	@mixin atMedium { font-size: 2.4rem; }
}

h2 {
	font-size: 1.6rem;
	@mixin atSmall { font-size: 1.8rem; }
	@mixin atMedium { font-size: 2rem; }
}

h1.heroTitle {
	text-shadow: 3px 3px 0 rgba(0, 0, 0, .2);

		@mixin atSmall { font-size: 2rem; }
		@mixin atMedium { font-size: 3rem; }
		@mixin atLarge { font-size: 3rem; }
	}

h3 {
	font-size: 1.4rem;
	@mixin atSmall { font-size: 1.4rem; }
	@mixin atMedium { font-size: 1.6rem; }
}

h4 {
	font-size: 1.2rem;
	@mixin atSmall { font-size: 1.2rem; }
	@mixin atMedium { font-size: 1.4rem; }
}


h5 {
	font-size: 1rem;
	@mixin atSmall { font-size: 1rem; }
	@mixin atMedium { font-size: 1rem; }

}

p.subTitle {
	text-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
	font-size: 1rem;

		@mixin atSmall { font-size: 1.2rem; }
	}